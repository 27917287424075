import React, { useEffect } from "react"
import { graphql, PageProps } from "gatsby"

import Layout from "../../../components/Layouts/mainPage"
import { NewHeader } from "../../../components/Headers/NewHeader"
import { HowInstallmentWorks } from "../../../components/HowInstallmentWorks"
import { FormPKW } from "../../../components/FormPKW"
import { NewFooter } from "../../../components/NewFooter"
import { Banner } from "../../../components/Banners/BannerNewYear"
import { getPageData } from "../../../helpers/getPageData"
import { TwoChoice } from "../../../components/twoChoice"

import { PageData } from "../../../interfaces/pageProps"
import { FourAdvantagesPoints } from "../../../components/FourAdvantagesPoints"
import { Partners } from "../../../components/PartnersNew"
import { BottomCta } from "../../../components/BottomCta"
import { newYearPartners } from "../../../components/PartnersNew/helpers"
import NewYearMediaRuby from "../../../components/BottomCta/BgImg/NewYearMediaRuby"

const formTitle = (
  <>
    Оформите карту сейчас <br /> и&nbsp;примите участие в&nbsp;розыгрыше
  </>
)
const timerDescr = (
  <>
    Играйте в игру с <span style={{ color: "#EC5C57", fontWeight: 500 }}>09.12 до 12.01</span>,
    получайте подарки от партнеров акции
  </>
)

const bottomTitle = (
  <>
    <b>Не упустите свой шанс!</b>
  </>
)

const firstBlockChoise = (
  <>
    Есть Халва – начинай игру сейчас!{" "}
    <span>Не&nbsp;упусти возможность участвовать в&nbsp;розыгрыше ценных призов</span>
  </>
)
const secondBlockChoise = (
  <>
    Нет Халвы – оформляй карту{" "}
    <span>
      {" "}
      и&nbsp;после получения <br className="d-none d-large-block" />
      не&nbsp;забудь зарегистрироваться в&nbsp;акции
    </span>
  </>
)

const subTitlePartners = <>Совершайте покупки и&nbsp;участвуйте в&nbsp;розыгрыше ценных призов</>

export default function Page({ data }: PageProps<PageData>) {
  const { ligal, noIndex } = getPageData(data)
  const countDownTime = 1800000
  // Получение первой страницы на которую зашел
  useEffect(() => {
    if (!sessionStorage.getItem("firstURL")) {
      sessionStorage.setItem("firstURL", document.URL)
    }
  }, [])

  const seoData = {
    title: "Бесплатная доставка карты Халва",
  }

  return (
    <Layout seoData={seoData} noHeader noFooter noIndex={noIndex}>
      <NewHeader showHint countDownTime={countDownTime} newYear />
      <Banner orderNum="1" />
      <FourAdvantagesPoints
        title="Преимущества с Халвой:"
        withTitle
        variant="newYearMedia"
        orderNum="2"
      />
      <HowInstallmentWorks
        orderNum="3"
        variant="newYearHalva"
        headTitle={<>Как принять участие в&nbsp;розыгрыше</>}
      />
      <FormPKW
        progressBar
        isGreenApprove
        title={formTitle}
        dataLayerName="shortPersonalForm"
        formBtnText="Оформить карту"
        orderNum="4"
        emptyForm
        backgroundColor="#EEF5FB"
        variant="newYearMedia"
        countDownTime={countDownTime}
        withTimer
        longTitle
        timerDescription={timerDescr}
        anotherTimerDescr={timerDescr}
      />
      <Partners
        title="Более 260&nbsp;000 магазинов-партнеров"
        subTitle={subTitlePartners}
        orderNum="5"
        variant="newYearMedia"
        additionalPartners={newYearPartners}
      />
      <TwoChoice
        newYear
        orderNum="6"
        textBlockTitle={firstBlockChoise}
        secondTextBlockTitle={secondBlockChoise}
        variant="newYearMediaRuby"
      />
      <BottomCta
        orderNum="7"
        BgImg={NewYearMediaRuby}
        variant="newYear"
        title={bottomTitle}
        newYear
      />
      <NewFooter ligal={ligal} orderNum="8" />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDynamicBanner {
      edges {
        node {
          name
          deskGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [1920, 3840]
              )
            }
          }
          mobGatsbyImg {
            url
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                quality: 80
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [600, 1200]
              )
            }
          }
        }
      }
    }
    admin {
      page(url: "https://halvacard.ru/order/pkw/novyi-god-halva/") {
        url
        notIndex
        ligal {
          text
        }
      }
    }
  }
`
